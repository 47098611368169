<template>
<div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">


        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form--> 
          <Form
            v-if="NotifyToken.token"
            @submit="onSubmitNotifyToken"
            :validation-schema="notifyTokenValidator"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">

                <BaseInput
                  label="Token"
                  required="required"
                  type="text"
                  name="token"
                  v-model="NotifyToken.token"
                />
    
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
  </div>
</template>

<script>
import BaseInput from "@/components/modals/BaseInput.vue";
import { defineComponent, onMounted, ref } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useTaxs from "@/core/services/api/tax/taxs";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "tax-notify-edit",
  components: {
    Form,
    BaseInput
  },
    props: ["id"],
  setup(props) {
    const router = useRouter();
    const { getNotifyToken , updateNotifyToken } = useTaxs();
    const { Sconfirm, SToast,SconfirmNc } = useSweetalert();
    const submitButton = ref(null);
    const NotifyToken = ref([]);

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    const notifyTokenValidator = Yup.object().shape({
      token: Yup.string()
        .required("กรุณากรอก Token")
    });

    const onSubmitNotifyToken = (values) => {
      Sconfirm("ยืนยัน การแก้ไข Token", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
              submitButton.value.setAttribute("disabled", "disabled");
            }
            await updateNotifyToken(values)
              .then(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
                SToast("success", "แก้ไข Token สำเร็จ");
              })
              .catch(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
              });
          }
        }
      );
    };



    onMounted(() => {
        if (!props.id) {
        return router.push({ name: "apps.taxs.notify.index" });
      }
            getNotifyToken()
        .then((response) => {
          NotifyToken.value = response.data.data;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
      checkPage("tax-notify-list");
      setCurrentPageBreadcrumbs("แก้ไข Notify", ["สอบถามภาษี","จัดการ Notify"]);
    });

    return {
      NotifyToken,
      submitButton,
      onSubmitNotifyToken,
      notifyTokenValidator,
       isLoading,
      color,
    };
  },
});
</script>
<style scoped>
</style>
